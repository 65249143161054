<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card custom-card overflow-hidden">
        <div class="card-header">
          <h4 class="m-0">Mis asistencias</h4>
        </div>
        <div class="card-body">
          <div class="main-content-calendar scrollx center">
            <!-- <div class="center"> -->
            <div class="main-content-body main-content-body-calendar alturaAuto">
              <FullCalendar class="main-calendar" :options="calendarOptions" />
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { reactive } from "vue-demi";
import { onMounted } from "@vue/runtime-core";
import { computed } from "@vue/runtime-core";
import { watch } from "@vue/runtime-core";
export default {
  components: {
    FullCalendar,
  },
  props: {
    attendances: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      document.querySelector(".fc-header-toolbar").classList.add("row");
      const toolbarChunks = document.getElementsByClassName("fc-toolbar-chunk");
      for (const element of toolbarChunks) {
        element.classList.add("col-md-4");
        element.classList.add("text-center");
      }
    });
    const calendarOptions = reactive({
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      headerToolbar: {
        left: "",
        center: "title",
        right: "",
      },
      views: {
        timeGridWeekCustom: {
          type: "timeGrid",
          duration: { days: 7 },
          buttonText: "Semana",
          allDaySlot: false,
          slotDuration: "01:00:00",
          expandRows: true,
        },
        timeGridDayCustom: {
          type: "timeGridDay",
          duration: { days: 1 },
          buttonText: "Día",
          allDaySlot: false,
          slotDuration: "01:00:00",
          expandRows: true,
        },
      },
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      },
      eventTimeFormat: {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      },
      events: props.attendances.map((attendance, index) => {
        return {
          id: index,
          // title: `Asistencia`,
          date: `${attendance.date}`,
          className: "bg-secondary",
        };
      }),
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      nowIndicator: true,
      buttonText: {
        prev: "Anterior",
        next: "Siguiente",
        today: "Hoy",
        month: "Mes",
        week: "Semana",
        day: "Día",
        list: "Agenda",
      },
      weekText: "Sm",
      allDayText: "Todo el día",
      moreLinkText: "más",
      noEventsText: "No hay eventos para mostrar",
      locale: {
        code: "es",
      },
    });

    watch(props.attendances, (val) => {
      const maxLength = val.length - 1;
      const attendance = val.at(-1);
      calendarOptions.events.push({
        id: maxLength,
        date: attendance.date,
        className: "bg-secondary",
      });
    });

    return {
      calendarOptions,
      // getEvents
    };
  },
};
</script>

<style scoped >
.scrollx {
  overflow-x: scroll;
}
.alturaAuto {
  min-width: 500px;
}
.center {
  padding: auto 100px;
}
</style>
